import cx from 'classnames';
import { CaretRightIcon, FilterIcon } from 'evergreen-ui';
import React, { useState } from 'react';

import { Spinner } from '@/components/Elements';

type TableColumn<Entry> = {
  title: string;
  titleRenderer?: (title: string) => React.ReactElement;
  titleClassName?: string;
  field: keyof Entry;
  className?: string;
  Cell?({ entry }: { entry: Entry }): React.ReactElement;
};

type verticalAlign =
  | 'align-baseline'
  | 'align-top'
  | 'align-middle'
  | 'align-bottom'
  | 'align-text-top'
  | 'align-text-bottom'
  | 'align-sub'
  | 'align-super';

export type Subhead = {
  type: 'subhead';
  title: string;
};

export type TableRow<Entry> = Entry & {
  subRows?: Array<Entry>;
};

export type TableProps<Entry> = {
  data: Array<TableRow<Entry> | Subhead>;
  columns: TableColumn<Entry>[];
  textWhenEmpty?: string;
  rowAlign?: verticalAlign;
  loading?: boolean;
  isFiltered?: boolean;
  unfilteredCount?: number;
  onClearFilterClick?: () => void;
};

function isSubhead(entry: any): entry is Subhead {
  return entry?.type === 'subhead';
}

export const Table = <Entry extends { id: string }>({
  data,
  columns,
  textWhenEmpty,
  rowAlign,
  loading,
  isFiltered,
  unfilteredCount = 0,
  onClearFilterClick = () => {},
}: TableProps<Entry>): JSX.Element => {
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  const toggleRowExpansion = (id: string): void => {
    setExpandedRows((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const renderRows = (entries: Array<TableRow<Entry> | Subhead>): JSX.Element[] =>
    entries.flatMap((entry) => {
      if (isSubhead(entry)) {
        return (
          <tr key={entry.title} className={cx('bg-gray-50 border-t border-neutral-300', rowAlign)}>
            <td
              colSpan={columns.length}
              className="px-6 py-2 whitespace-nowrap font-medium text-gray-500"
            >
              {entry.title}
            </td>
          </tr>
        );
      }

      const hasSubRows = !!entry.subRows?.length;
      const isExpanded = expandedRows[entry.id];

      return [
        <tr
          key={entry.id}
          className={cx('bg-white border-t border-neutral-300 hover:bg-neutral-150', rowAlign)}
          data-testid={entry.id}
        >
          {columns.map(({ Cell, field, title, className }, columnIndex) => (
            <td
              key={`${title}${columnIndex}`}
              className={cx(
                'px-6 py-4 whitespace-nowrap font-medium',
                columnIndex ? 'text-neutral-700' : 'text-gray-900',
                className
              )}
            >
              {columnIndex === 0 && hasSubRows ? (
                <div className="inline-flex items-center">
                  <button onClick={() => toggleRowExpansion(entry.id)} className="ml-3.5 mr-[22px]">
                    <CaretRightIcon
                      className={cx(
                        'text-neutral-600 transform transition-transform duration-300 ease-in-out',
                        {
                          'rotate-90': isExpanded,
                          'rotate-0': !isExpanded,
                        }
                      )}
                    />
                  </button>
                  {Cell ? <Cell entry={entry} /> : entry[field]}
                </div>
              ) : (
                <>{Cell ? <Cell entry={entry} /> : entry[field]}</>
              )}
            </td>
          ))}
        </tr>,
        ...(hasSubRows && isExpanded
          ? (entry.subRows || []).map((subEntry) => (
              <tr
                key={`sub-${subEntry.id}`}
                className={cx(
                  'bg-white border-t border-neutral-300 hover:bg-neutral-150',
                  rowAlign
                )}
                data-testid={subEntry.id}
              >
                {columns.map(({ Cell, field, title, className }, columnIndex) => (
                  <td
                    key={`${title}-sub-${columnIndex}`}
                    className={cx(
                      'px-6 py-4 whitespace-nowrap font-medium',
                      columnIndex ? 'text-neutral-700' : 'text-gray-900',
                      columnIndex === 0 ? 'pl-28' : '',
                      className
                    )}
                  >
                    {Cell ? <Cell entry={subEntry} /> : subEntry[field]}
                  </td>
                ))}
              </tr>
            ))
          : []),
      ];
    });

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border border-neutral-300 sm:rounded-lg">
            {isFiltered && (
              <div className="flex items-center justify-start px-6 py-4">
                <FilterIcon className="mr-2 text-neutral-600" />
                <span className="text-neutral-700 text-xs">
                  Filter applied. {data.length}/{unfilteredCount} fields shown.{' '}
                  <span
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onClearFilterClick();
                      }
                    }}
                    onClick={onClearFilterClick}
                    className="text-[#3366FF] cursor-pointer"
                  >
                    Clear filter
                  </span>
                </span>
              </div>
            )}
            <table className="min-w-full divide-y divide-gray-200" role="table">
              <thead className="bg-neutral-200">
                <tr>
                  {columns.map((column, index) => (
                    <th
                      key={`${column.title}${index}`}
                      scope="col"
                      className={`px-6 py-3 text-left text-sm font-bold text-neutral-700 uppercase tracking-wider ${
                        column.titleClassName || ''
                      }`}
                    >
                      {column.titleRenderer ? column.titleRenderer(column.title) : column.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={columns.length}>
                      <div className="text-gray-500 h-44 flex justify-center items-center flex-col">
                        <Spinner size="lg" />
                      </div>
                    </td>
                  </tr>
                ) : data.length ? (
                  renderRows(data)
                ) : (
                  <tr>
                    <td colSpan={columns.length} className="text-center py-4 text-neutral-500">
                      {textWhenEmpty ?? 'No Entries Found'}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
